.features-section {
  background: linear-gradient(to right, #240024, rgb(14, 11, 41), #240024);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 0rem;
  overflow: hidden;
  position: relative;
  min-height: 60vh;
}

.container {
  width: 100%;
  max-width: 1280px;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-container {
  transform: translateY(-300px);
}

.features-title {
  font-size: clamp(0.7rem, 15vw, 10rem);
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: white;
  text-shadow: 0 0 10px #ff2975, 0 0 20px #00ddff;
  letter-spacing: 5px;
  transform: scale(clamp(1, 8vw, 5));
  transform: translateY(-70px);
  transform-origin: center center;
  width: 100%;
  position: relative;
  z-index: -1;
  transition: transform 0.2s ease-out;
  will-change: transform;
  padding-bottom: 20px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  padding-top: 23rem;
  justify-items: center;
  position: relative;
  z-index: 2;
  margin-top: 0;
}

.feature-card {
  padding: 1.5rem;
  border-radius: 0.75rem;
  text-align: center;
  transition: all 0.3s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 41, 55, 0.45);
  transition: all 0.3s ease;
}

.feature-card:hover::before {
  background-color: rgba(31, 41, 55, 0.8);
}

.feature-card > * {
  position: relative;
  z-index: 1;
}

.feature-icon {
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-icon {
  transform: scale(1.1) rotate(8deg);
}

.feature-card:hover {
  box-shadow: 0px 2px 5px 0px #28bfff, 0 -2px 5px 0px #f694ff,
    0 0px 10px 0px #a201ff;
}

.icon {
  width: 3rem;
  height: 3rem;
  color: #3b82f6;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #00ddff;
  filter: drop-shadow(0 0 5px rgba(0, 221, 255, 0.7));
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
}

.feature-card:hover .feature-title {
  color: #62eaff;
}

.feature-description {
  font-size: 1.125rem;
  color: #efefef;
  transition: color 0.3s ease;
}

.feature-card:hover .feature-description {
  color: #f3f4f6;
}

@media (max-width: 768px) {
  .feature-card {
    padding: 0.5rem;
    border-radius: 0.5rem;
    max-width: 150px;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .feature-title {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }

  .feature-description {
    font-size: 0.8rem;
  }

  .features-grid {
    gap: 2rem;
    padding-top: 20rem;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 70px;
    padding-bottom: 0px;
  }

  .features-title {
    font-size: 3rem;
    margin-bottom: 2rem;
    letter-spacing: 3px;
    padding-right: 60px;
    padding-top: 130px;
    padding-bottom: 20px;
  }
}
